@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;400;600;700;900&display=swap');

* {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Heebo', sans-serif;
    background: #20242b08;
}


.react-cookienotice-root {
    z-index: 100;
}

::selection {
    background: #3bd378;
    color: white;
}

@keyframes skeleton {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
}

.skeleton {
    background-color: rgba(240, 240, 240, 1);
    background-image: linear-gradient(
            90deg,
            rgba(240, 240, 240, 1),
            rgba(240, 240, 240, 1),
            rgba(255, 255, 255, 1),
            rgba(240, 240, 240, 1),
            rgba(240, 240, 240, 1)
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 2px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    animation: skeleton 500ms ease-in-out infinite;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.swal2-styled.swal2-confirm {
    background-color: #3b97d3 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(59 151 211 / 50%) !important;
}

.swal2-icon.swal2-warning {
    color: #235a7e !important;
    border-color: #235a7e !important;
}

.swal2-icon.swal2-success {
    color: #3acc8e !important;
    border-color: #3acc8e !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #3acc8e !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
    border-color: #3acc8e !important;
}

@media screen and (max-width: 1400px) {
    ._1AuzD {
        bottom: inherit !important;
        top: 16px !important;
    }

    ._1pNzT {
        font-size: 14px !important;
        padding: 8px !important;
    }
}


@media screen and (max-width: 768px) {
    ._1AuzD {
        bottom: 0 !important;
        top: inherit !important;
    }

    ._1pNzT {
        font-size: 14px !important;
        padding: 8px !important;
        margin: 0 !important;
        margin-left: 16px !important;
        margin-bottom: 16px !important;
    }
}
